<template>
  <div>
    <el-row
      type="flex"
      class="row-bg"
      justify="space-between"
      align="center">
      <el-col :span="12">
        <div class="title">
          <h1>
            Создание новости
          </h1>
        </div>
      </el-col>
    </el-row>
    <news-form @submit="createNews" />
  </div>
</template>
<script>

// eslint-disable-next-line import/no-unresolved
import NewsForm from '@/components/news/newsForm.vue';

export default {
  name: 'CreatingNews',
  components: {
    NewsForm,
  },
  methods: {
    createNews() {},
  },
};
</script>
<style lang="scss">
</style>
